body {
	background: #1f2227;
	height: calc(100vh - 60px);
}
#root {
	height: 100%;
}
#app {
	height: 100%;
}
.flex-grow-1 {
	height: 100%;
}
.spinner {
	background-color: #1f2227;
}
.spinner-logo {
	position: absolute;
	display: flex;
	justify-content: center;
	height: 30vh;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
#popout-polyViewer {
	width: 100%;
	height: 100%;
	display: grid;
	background: #1f2227;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	justify-items: center;
}
#polyViewer {
	display: flex;
	width: 100%;
	height: 100%;
	padding-top: 60px;
}
.popout-poly-viewer-container {
	display: flex;
	background-color: yellow;
	width: 100%;
	height: 100%;
}
.poly-viewer-container {
	display: flex;

	width: 100%;
	height: 100%;
}
.flex > .account-view {
	margin: 0;
	padding: 0;
	background: white;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.poly-sub-container {
	overflow-y: scroll;
	height: 90%;
	width: 100%;
	padding-top: 0.75em;
	scrollbar-color: #191b1f #24272f;
}
::-webkit-scrollbar {
	height: 12px;
	width: 12px;
	background: #1f2227;
}

::-webkit-scrollbar-thumb {
	background: #121214;
	-webkit-border-radius: 1ex;
	-webkit-box-shadow: 0px 1px 2px #1f2227;
}

::-webkit-scrollbar-corner {
	background: #1f2227;
}
.poly-grid-toggle-toolbar-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.75em;
	text-align: center;
	background: #1f2227;
}
.button-group {
	display: block;
}
.button-group button {
	margin: 0.25em;
	font-size: 16px;
	width: 50px;
	background-color: #3a3a3a;
}
.poly-account-dashboard-container {
	margin-left: 1em;
	text-align: left;
	background-color: #24272f;

	margin-top: 0;
	overflow-y: hidden;
	overflow-x: hidden;
}
.poly-account-dashboard {
	display: inline-block;
	text-align: center;
}
#polyViewer .account-view-container {
	margin: 5px;
	text-align: left;
	background-color: #3134396b;
}
.medium-view {
	width: 450px;
	height: 190px;
}
.small-view {
	width: 350px;
	height: 150px;
	margin: 5px;
}
.large-view {
	width: 610px;
	height: 255px;
	margin: 5px;
	text-align: left;
	background-color: #3134396b;
}
.navbar {
	width: 100%;
	height: 60px;
	margin: 0 auto;
	background-color: #0e1424;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
}
.navigation ul li {
	margin-left: 3em;
}
.navigation ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: flex-start;
}
.add-on-disabled {
	background-color: #019eff1e !important;
	color: rgba(167, 167, 167, 0.468) !important;
}
.show-formations-btn {
	background-color: #424c5b !important;
}
.add-on-color {
	background-color: #019eff3c !important;
	// color: rgb(167, 167, 167) !important;
}
.members-toggled-button {
	margin-top: 2em;
	background-color: #4d01ff53 !important;
}
.f2p-toggled-button {
	background-color: rgba(127, 255, 133, 0.581) !important;
}
.feature-disabled {
	background-color: #f8f8f808 !important;
	color: rgba(167, 167, 167, 0.197) !important;
	border: none !important;
}
.add-on-disabled:hover {
	background-color: #019eff7c !important;
}
.dashboard-main-button {
	font-size: 1rem;
	padding: 0.1em;
	cursor: pointer;

	width: 80%;
	min-height: 37px;

	font-weight: 600;
	margin-top: 0.75em;
	background-color: #1b1c1f;
	border: solid 1px #1b1c1f;
	border-radius: 5px;
	color: white;
}
.dashboard-main-button:hover {
	background-color: #2c2e31;
}
.toggle-off-button:hover {
	background-color: #1b1c1f !important;
}

.server-online-notifier {
	border-radius: 100%;
	width: 9px;
	height: 9px;
	background-color: rgb(0, 255, 34);
	display: inline-block;
	margin-left: 0.5em;
}
.navbar-title {
	color: white;
	text-align: left;
	font-weight: 700;
	font-size: 0.8em;
	line-height: 3.5;
}
.navbar-logo {
	width: 45px;
	height: auto;
	padding-top: 11px;
}
.account-view:hover {
	opacity: 0.2;
}
.account-view-container:hover {
	.hover-info-container {
		display: block !important;
	}
}
.minimap-out-of-sync-overlay {
	display: block;
	position: absolute;
	font-size: 40px;
	color: white;
	background-color: red;
	text-align: center;
	z-index: 1000;
	width: 100%;
	height: 100%;
	opacity: 0.33;
}
.out-of-sync-overlay {
	display: block;
	position: absolute;
	font-size: 40px;
	color: white;
	background-color: red;
	text-align: center;
	z-index: 9;
	width: 100%;
	height: 100%;
	opacity: 0.33;
}
.sync-notifier-section {
	text-align: center;
	padding-top: 0.7em;
}
.disabled-sync-notifier {
	opacity: 0.6;
}
.checkbox-container {
	cursor: pointer;

	display: flex;
	align-items: start;
	padding-left: 70px;

	img {
		margin-right: 0.5em;
		margin-top: 18px;
		width: 30px;
		height: 30px;
	}
}
.out-of-sync-image-container {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	display: flex;
	flex-direction: column;
}
.grid-out-of-sync-image-container {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	.out-of-sync-image {
		margin-left: 40px;
		width: 20px;
		height: 20px;
	}
}
.out-of-sync-image {
	width: 42px;
	height: 42px;
	padding: 0.45em;
	border: solid 1px rgb(255, 0, 0);
	background-color: rgba(2, 5, 22, 0.366);
	padding: 0.5em;
}
.checkbox {
	label {
		padding-left: 0.25em;

		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}
}
.custom-checkbox {
	width: initial !important;
}
.account-view-container {
	position: relative;
	display: inline-block;

	.hover-info-container {
		display: none;
		position: absolute;
		z-index: 10;
		font-size: 40px;
		color: white;
		text-align: center;
		width: 100%;
		top: 20px;

		.title-area {
			display: inline-block;
		}
		.hover-only-title {
			text-align: center;
			display: inline-block;
			width: 100%;
			font-weight: 600;
		}
	}
}
.hitpoints-display {
	width: 100%;
	min-width: 15px;
}
.prayer-display {
	width: 70%;
	min-width: 15px;
}
.hitpoints-display .percentage-filler {
	background-color: #d33333b7;
}
.prayer-display .percentage-filler {
	background-color: rgba(85, 194, 245, 0.774);
}
.hitpoints-display .percentage-container .percentage-filler img {
	width: 75%;
	margin-top: 13px;
}
.prayer-display .percentage-container {
	margin-left: 2px;
}
.percentage-filler {
	width: 100%;

	border-radius: inherit;
	text-align: center;
	position: absolute;
	bottom: 0;
}
.percentage-filler img {
	width: 90%;
	margin-top: 10px;
}
.percentage-filler span {
	font-size: 11px;
	font-weight: 700;
	text-align: left;
	color: white;
}
.percentage-container {
	height: 100%;

	background-color: rgb(20, 19, 19);
	border-radius: 1px;

	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: relative;
}
.stats-area {
	border: solid 4px;
	height: 100%;
	width: 10%;
	position: relative;
	display: flex;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.account-view {
	max-width: 765px;
	max-height: 503px;
	position: relative;
	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
	z-index: 100;
	justify-content: safe;
}

.inventory-image-container {
	position: relative;
}
.button-info-section-container {
	color: white;
	width: 100%;
	padding-top: 0.25em;
	text-align: center;
	position: relative;
	z-index: 10;
	overflow: hidden;
	padding-bottom: 2em;
}
.top-toolbar-container {
	background-color: #24272f;
	position: relative;
	height: calc(100vh - 60px);
	padding: 0.5em;
	padding-bottom: 0;
	padding-top: 0;

	width: 350px;

	flex: none;
	overflow-y: scroll;
	text-align: center;
	overflow-x: hidden;
}
.map-section-container {
	height: 30%;
	max-height: 300px;
	min-height: 250px;
	width: 330px;
	position: relative;
	display: inline-block;
	bottom: 0;

	margin-top: 20px;
}
.map-section {
	position: absolute;
	left: 0;
	top: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);


}
.map-account-icon {
	width: 2px;
	height: 2px;
	// background-color: #2c527a;
	position: absolute;

	&.no-icon {
		display: none !important;
	}
}

.map-view {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 10px;
	border: solid 0px;
	z-index: 55;
	background: black;
	position: absolute;
	margin: auto;
}
.map-view-border {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 55px;

	z-index: 55;
	background: none;
	position: absolute;
	top: -5px;
	left: -5px;
	margin: auto;
	padding: 5px;
}
.map-view img {
	width: 256px;
}
.game-screen-image-container {
	position: relative;
	top: 0;
	left: 0;
}
.terminate_button {
	background: #b03939;
	margin-top: 2em;
}
.start-echo-button {
	border: solid 1px rgb(238, 238, 238);
}

.enable-formation-button {
	background: rgb(114, 240, 124);
	color: black;
}
.disable-formation-button {
	background: rgb(241, 114, 114);
	color: black;
}
.stop-echo-button {
	background: rgb(89, 165, 246);
	color: white;
	margin-bottom: 1em;
}
.stop-echo-button:hover {
	background: rgb(22, 24, 26);
}
.login-screen-image-container {
	width: 650px;
	height: 195px;
}
.login-screen-image-container img {
	position: relative;
	object-fit: cover;
	width: 100%;
	height: 195px;
}
.inventory-image-container > img {
	object-fit: fill;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.game-screen-image-container > img {
	object-fit: cover;
	width: 100%;
}

.chatbox-image-container > img {
	object-fit: cover;
	width: 100%;
	height: auto;
	position: absolute;
	left: 0;
	bottom: 0;
}
