// gradient: https://www.gradientmagic.com/collection/rainbowstandard
@import url('https://fonts.googleapis.com/css?family=Lato:300');

.gradient-border {
	--border-width: 4px;
	&.gradient-bg {
		--border-width: 500px;
	}
	&.toggled {
		--border-width: 2px;
	}
	position: relative;
	justify-content: center;
	align-items: center;
	color: white;
	background: #222;
	border-radius: var(--border-width);

	&::after {
		position: absolute;
		content: '';
		top: calc(-1 * var(--border-width));
		left: calc(-1 * var(--border-width));
		z-index: -1;
		width: calc(100% + var(--border-width) * 2);
		height: calc(100% + var(--border-width) * 2);
		background: linear-gradient(60deg, #79d2ff, #79d2ff, #79d2ff, #79d2ff);
		filter: blur(5px);

		opacity: 1;
		background-size: 300% 300%;
		background-position: 0 50%;
		border-radius: calc(2 * var(--border-width));
		animation: moveGradient 4s alternate infinite;
	}
	&.gradient-bg {
		background: none;
		.toggled {
			background: none !important;
		}
		.toggled::after {
			background: linear-gradient(60deg, #ff4444, #ff8f44, #ffec44, #ff4444);
			filter: blur(0px);
		}
	}
	&.gradient-bg::after {
		position: absolute;
		content: '';
		top: calc(-1 * var(--border-width));
		left: calc(-1 * var(--border-width));
		z-index: -1;
		width: calc(100% + var(--border-width) * 2);
		height: calc(100% + var(--border-width) * 2);

		background: linear-gradient(
			60deg,
			hsl(224, 85%, 66%),
			hsl(192, 85%, 66%),
			hsl(209, 85%, 66%)
		);

		filter: blur(100px);

		opacity: 0.1;
		background-size: 100% 100%;
		background-position: 50% 50%;
		border-radius: calc(2 * var(--border-width));
		animation: moveGradient 3s alternate infinite;
	}
}
.map-view-border {
	background: linear-gradient(30deg, #ff4444, #ffffff00, #ff4444);
	background-size: 100% 100%;
	background-position: 50 50%;
	//   border-radius: calc(2 * var(--border-width));
	animation: moveGradient 2s alternate infinite;
}
.map-view {
	background: linear-gradient(
		60deg,
	
		hsl(222, 89%, 93%),
		hsl(191, 92%, 90%),
		hsl(208, 100%, 94%)
	);
	background-size: 300% 300%;
	background-position: 0 50%;
	//   border-radius: calc(2 * var(--border-width));
	animation: moveGradient 4s alternate infinite;
}
@keyframes moveGradient {
	50% {
		background-position: 100% 50%;
	}
}

/*
 * Utility functions
 */

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

/*
 * Button dimensions
 */

$width: 300px;
$height: 80px;

/*
 * Button border dimensions
 */

// Border length percentages from the full dimensions
$horizontal-border-percentage: 0.2;
$vertical-border-percentage: 0.8;

// Border lengths
$horizontal-border: $width * $horizontal-border-percentage;
$vertical-border: $height * $vertical-border-percentage;

// Border thickness (1 or 2 pixels are visually looking good)
$border-thickness: 2px;

/*
 * Button text size
 */

$font-size: 28px;
$letter-spacing: 11px;

/*
 * Button colors
 */

$text-color: #ffffff;
$toggled-text-glow-color: #ff4444;
$not-toggled-text-glow-color: #49c2ff8f;
$toggled-border-color: #ff4444;
$not-toggled-border-color: #79d2ff;
/*
 * Animation duration variables
 */

// Duration of animating one pixel
$duration: 0.0005s;

// Vertical border animation duration
$vertical-border-duration: strip-unit($vertical-border) * $duration;

// Horizontal border animation duration
$horizontal-border-duration: $vertical-border-duration +
	strip-unit($width - $horizontal-border) * $duration;

/*
 * Button glowing border
 */

$glowing-border-toggled: 0 0 3px $toggled-border-color,
	0 0 6px $toggled-border-color, 0 0 9px $toggled-border-color,
	0 0 12px $toggled-border-color, 0 0 15px $toggled-border-color,
	0 0 18px $toggled-border-color, inset 0 0 3px $toggled-border-color,
	inset 0 0 6px $toggled-border-color, inset 0 0 9px $toggled-border-color;

$glowing-border-not-toggled: 0 0 3px $not-toggled-border-color,
	0 0 6px $not-toggled-border-color, 0 0 9px $not-toggled-border-color,
	0 0 12px $not-toggled-border-color, 0 0 15px $not-toggled-border-color,
	0 0 18px $not-toggled-border-color, inset 0 0 3px $not-toggled-border-color,
	inset 0 0 6px $not-toggled-border-color,
	inset 0 0 9px $not-toggled-border-color;

@keyframes upper-horizontal-border {
	@if $vertical-border < $width - $horizontal-border {
		0% {
			transform: translateX(0%) scaleX(1);
		}

		#{
			strip-unit($vertical-border-duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX(0%)
				scaleX(($vertical-border + $horizontal-border) / $horizontal-border);
		}

		#{
			(strip-unit($vertical-border-duration / $horizontal-border-duration) + strip-unit($width - $horizontal-border - $vertical-border) * $duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX($width - $horizontal-border - $vertical-border)
				scaleX(($vertical-border + $horizontal-border) / $horizontal-border);
		}

		100% {
			transform: translateX($width - $horizontal-border) scaleX(1);
		}
	} @else {
		0% {
			transform: translateX(0%) scaleX(1);
		}

		#{
			(strip-unit($width - $horizontal-border) * $duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX(0%) scaleX($width / $horizontal-border);
		}

		#{ $vertical-border-duration / $horizontal-border-duration * 100% } {
			transform: translateX(0%) scaleX($width / $horizontal-border);
		}

		100% {
			transform: translateX($width - $horizontal-border) scaleX(1);
		}
	}
}

@keyframes lower-horizontal-border {
	@if $vertical-border < $width - $horizontal-border {
		0% {
			transform: translateX(0%) scaleX(1);
		}

		#{
			strip-unit($vertical-border-duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX(0%)
				scaleX(($vertical-border + $horizontal-border) / $horizontal-border);
		}

		#{
			(strip-unit($vertical-border-duration / $horizontal-border-duration) + strip-unit($width - $horizontal-border - $vertical-border) * $duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX(-($width - $horizontal-border - $vertical-border))
				scaleX(($vertical-border + $horizontal-border) / $horizontal-border);
		}

		100% {
			transform: translateX(-($width - $horizontal-border)) scaleX(1);
		}
	} @else {
		0% {
			transform: translateX(0%) scaleX(1);
		}

		#{
			(strip-unit($width - $horizontal-border) * $duration / $horizontal-border-duration)
			*
			100%
			} {
			transform: translateX(0%) scaleX($width / $horizontal-border);
		}

		#{ $vertical-border-duration / $horizontal-border-duration * 100% } {
			transform: translateX(0%) scaleX($width / $horizontal-border);
		}

		100% {
			transform: translateX(-($width - $horizontal-border)) scaleX(1);
		}
	}
}

.dashboard-start-button {
	font-size: 1rem;
	padding-left:1em;
	padding-right:1em;
	padding-top: calc(.5em - 1px);
	cursor:pointer;

	width: 80%;
	height: 40px;
	
	font-weight: 600;
	margin-top: 20px;

	border: solid 1px #1b1c1f;
	border-radius: 5px;
	margin-bottom: 20px;
	position: relative;
	width: $width;
	height: $height;
	background: none;
	&.toggled {
		// height: 67px !important;
		background: none !important;
		// border: solid 6px #79d2ff;
	}
	border: none;
	font-family: 'Quicksand', sans-serif;
	font-size: $font-size;
	text-transform: uppercase;
	letter-spacing: $letter-spacing;
	color: $text-color;

	&:hover {
		cursor: pointer;
	}
}

.dashboard-start-button:focus-within {
	outline: none;
}

.toggled span:nth-of-type(n + 7) {
	text-shadow: 0 0 5px $toggled-text-glow-color,
		0 0 20px $toggled-text-glow-color, 0 0 10px $toggled-text-glow-color,
		0 0 20px $toggled-text-glow-color, 0 0 120px $toggled-text-glow-color,
		0 0 0px $toggled-text-glow-color;
	will-change: text-shadow;
}
.not-toggled span:nth-of-type(n + 7) {
	text-shadow: 0 0 10px $not-toggled-text-glow-color,
		0 0 20px $not-toggled-text-glow-color, 0 0 40px $not-toggled-text-glow-color,
		0 0 80px $not-toggled-text-glow-color,
		0 0 120px $not-toggled-text-glow-color,
		0 0 160px $not-toggled-text-glow-color;
	will-change: text-shadow;
}

.toggled:hover span:nth-of-type(n + 7) {
	text-shadow: 0 0 10px $toggled-text-glow-color,
		0 0 10px $toggled-text-glow-color, 0 0 20px $toggled-text-glow-color,
		0 0 20px $toggled-text-glow-color, 0 0 40px $toggled-text-glow-color,
		0 0 80px $toggled-text-glow-color, 0 0 120px $toggled-text-glow-color,
		0 0 160px $toggled-text-glow-color;
}

.not-toggled:hover span:nth-of-type(n + 7) {
	text-shadow: 0 0 10px $not-toggled-text-glow-color,
		0 0 10px $not-toggled-text-glow-color, 0 0 20px $not-toggled-text-glow-color,
		0 0 20px $not-toggled-text-glow-color, 0 0 40px $not-toggled-text-glow-color,
		0 0 80px $not-toggled-text-glow-color 0 0 120px $not-toggled-text-glow-color,
		0 0 160px $not-toggled-text-glow-color !important;
}

.dashboard-start-button span:last-of-type {
	letter-spacing: 0;
}

.dashboard-start-button span:nth-of-type(1) {
	position: absolute;
	top: 0;
	left: 0;
	width: $border-thickness;
	height: $vertical-border;
	box-shadow: $glowing-border-toggled;
	transform-origin: top;
	will-change: transform;
}
.not-toggled span:nth-of-type(1) {
	box-shadow: $glowing-border-not-toggled;
}

.dashboard-start-button__hover span:nth-of-type(1) {
	transform: scaleY(0);
	transition: transform $vertical-border-duration linear;
}
.dashboard-start-button:hover span:nth-of-type(1) {
	transform: scaleY(0);
	transition: transform $vertical-border-duration linear;
}

.dashboard-start-button span:nth-of-type(2) {
	position: absolute;
	top: 0;
	left: 0;
	width: $horizontal-border;
	height: $border-thickness;
	box-shadow: $glowing-border-toggled;
	transform-origin: left;
	will-change: transform;
}

.not-toggled span:nth-of-type(2) {
	box-shadow: $glowing-border-not-toggled;
}
.dashboard-start-button__hover span:nth-of-type(2) {
	animation: upper-horizontal-border $horizontal-border-duration linear forwards;
}
.dashboard-start-button:hover span:nth-of-type(2) {
	animation: upper-horizontal-border $horizontal-border-duration linear forwards;
}
.dashboard-start-button span:nth-of-type(3) {
	position: absolute;
	top: 0;
	right: 0;
	width: $border-thickness;
	height: $vertical-border;
	box-shadow: $glowing-border-toggled;
	transform-origin: top;
	transform: scaleY(0);
	will-change: transform;
}
.not-toggled span:nth-of-type(3) {
	box-shadow: $glowing-border-not-toggled;
}
.dashboard-start-button__hover span:nth-of-type(3) {
	transform: scaleY(1);
	transition: transform $vertical-border-duration linear
		strip-unit($width - $horizontal-border) * $duration;
}
.dashboard-start-button:hover span:nth-of-type(3) {
	transform: scaleY(1);
	transition: transform $vertical-border-duration linear
		strip-unit($width - $horizontal-border) * $duration;
}

.dashboard-start-button span:nth-of-type(4) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: $border-thickness;
	height: $vertical-border;
	box-shadow: $glowing-border-toggled;
	transform-origin: bottom;
	will-change: transform;
}
.not-toggled span:nth-of-type(4) {
	box-shadow: $glowing-border-not-toggled;
}
.dashboard-start-button__hover span:nth-of-type(4) {
	transform: scaleY(0);
	transition: transform $vertical-border-duration linear;
}
.dashboard-start-button:hover span:nth-of-type(4) {
	transform: scaleY(0);
	transition: transform $vertical-border-duration linear;
}

.dashboard-start-button span:nth-of-type(5) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: $horizontal-border;
	height: $border-thickness;
	box-shadow: $glowing-border-toggled;
	transform-origin: right;
	will-change: transform;
}
.not-toggled span:nth-of-type(5) {
	box-shadow: $glowing-border-not-toggled;
}
.dashboard-start-button__hover span:nth-of-type(5) {
	animation: lower-horizontal-border $horizontal-border-duration linear forwards;
}
.dashboard-start-button:hover span:nth-of-type(5) {
	animation: lower-horizontal-border $horizontal-border-duration linear forwards;
}

.dashboard-start-button span:nth-of-type(6) {
	position: absolute;
	bottom: 0;
	left: 0;
	width: $border-thickness;
	height: $vertical-border;
	box-shadow: $glowing-border-toggled;
	transform-origin: bottom;
	transform: scaleY(0);
	will-change: transform;
}
.not-toggled span:nth-of-type(6) {
	box-shadow: $glowing-border-not-toggled;
}
.dashboard-start-button__hover span:nth-of-type(6) {
	transform: scaleY(1);
	transition: transform $vertical-border-duration linear
		strip-unit($width - $horizontal-border) * $duration;
}
.dashboard-start-button:hover span:nth-of-type(6) {
	transform: scaleY(1);
	transition: transform $vertical-border-duration linear
		strip-unit($width - $horizontal-border) * $duration;
}
