.delay-slider-container {
	width: 80%;
	text-align: center;
	display: inline-block;
	margin-top: 1em;
}
.disabled-no-auto-eat {
	opacity: 0.5;
}
.slider-title {
	margin-bottom: 0.1em;
	margin-top: 0.05em;
}
.horizontal-slider {
	width: 100%;
	max-width: 500px;
	height: 70px;
	margin: auto;
}
.example-thumb {
	cursor: pointer;
	position: absolute;
	z-index: 100;
	background: #ffffff;
	border: 5px solid #ffffff;
	border-radius: 100%;
	display: block;
}
.example-thumb.active {
	background-color: grey;
}
.example-track {
	position: relative;
	background: #ddd;
}
.example-track.example-track-0 {
	background: #83a9ff;
}
.eat-track.eat-track-0 {
	background: rgb(248, 71, 71);
}
.combo-eat-track.combo-eat-track-0 {
	background: rgb(245, 88, 198);
}
.prayer-track.prayer-track-0 {
	background: rgb(74, 234, 255);
}
.drop-value-track.drop-value-track-0 {
	background: rgb(255, 228, 74);
}
.position-radius-track.position-radius-track-0 {
	background: rgb(74, 164, 255);
}
.horizontal-slider .example-track {
	top: 20px;
	height: 4px;
}
.horizontal-slider .example-thumb {
	top: 12px;
	width: 10px;
	outline: none;
	height: 10px;
	line-height: 78px;
}
