.login-section-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	z-index: 4;
	position: relative;
	height: 100vh;

}

.login-error {
	padding-top:1em;
	font-size: 1.3vw;
	font-weight: 500;
}
.disabled-dashboard-bg {
	position: absolute;
	top:0;
	left:0;
	opacity: 0.75;
	filter:blur(12px);
	z-index: 0;
	height:100vh;
	overflow: hidden;
}
.login-bg-container {
	position: relative;
	background-color: black;
}
.error-splash {
	width: 100%;
	height: 1vh;
	margin: 0 auto;
	background-color: #24272f;
	padding: 5px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #b03939;
	text-align: center;
	border-top: solid 2px;
	padding: 2em;
    font-size: 1vw;
}
.toggle-formation-button {
	width: 70%;
	background: #435081;
}
input {
    padding: 0.1em;
	font-size: 1vw;
}
.formations-toggler {
}
.login-section {

	max-width: 30vw;
	width: 600px;
	padding-top: 2em;
	text-align: center;
	color: white;
	font-size: 1.1vw;
	background-color: #345888;
	padding:4em;
	padding-top:1.5em;
	border-radius: 10px;
}
.server-address {
    position: absolute;
	right: 100px;
	margin-right: 40px;
    font-size: .8em;
    line-height: 3.5;
}
.logout-title {
	position: absolute;
	right: 0;
	margin-right: 40px;
	cursor: pointer;
}
.login-section button {
	width: 100%;
}
.login-logo {
	padding-bottom: 2em;
	width: 60%;
    height:60%;
}
input {
	margin-top: 1.2em;
	width: calc(100% - 1em);
	margin-bottom: 1em;
    border-radius: 10px;
	padding:.5em;

}
form {
	text-align: left;
}
label {
	font-size: 16px;
}
